<template>
  <div class="d-flex w-100">
    <b-card
      class="w-100 py-1 px-md-2 min-h-60 no-border"
      body-class="p-0 p-md-4"
    >
      <b-alert
        :show="[2, 3].includes(selectedMenu.status)"
        :variant="variantStatus(selectedMenu.status).variant"
      >
        {{ variantStatus(selectedMenu.status).label }}
      </b-alert>
      <router-view />
    </b-card>
    <context-menu ref="context" right v-if="menus">
      <b-button
        class="text-left d-flex align-items-center justify-content-between px-2"
        block
        v-for="(menu, index) in menus"
        :key="index"
        @click="handleSelectMenu(menu)"
        :variant="selectedMenu.url === menu.url ? 'primary' : 'light'"
      >
        <span> {{ menu.name }}</span>
        <b-icon
          v-b-popover.hover.top="variantStatus(menu.status).label"
          :icon="variantStatus(menu.status).icon"
          :variant="variantStatus(menu.status).variant"
          class="ml-3"
        />
      </b-button>
    </context-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      homologationId: "",
      serviceId: "",
      selectedMenu: "",
      menus: [],
    };
  },
  computed: {
    ...mapGetters(["user", "service_validation_history"]),
  },
  methods: {
    variantStatus(status) {
      let icon;
      let variant;
      let label;
      switch (status) {
        case 1:
          icon = "check-circle";
          variant = "success";
          label = "Valiação concluída";
          break;
        case 2:
          icon = "x-circle";
          variant = "danger";
          label = "Não há dados para validar";
          break;
        case 3:
          icon = "clock";
          variant = "warning";
          label = "Aguardando validação";
          break;
      }
      return {
        icon,
        variant,
        label,
      };
    },
    selectedRoute() {
      const routeMenu = this.$route.path
        .split("/")
        .filter((path) => path)
        .slice(-1)[0];
      this.selectedMenu = this.menus.find(({ url }) => url === routeMenu) || {
        status: 1,
      };
    },

    handleSelectMenu(menu) {
      if (this.selectedMenu.url === menu.url) {
        return;
      }
      let path = `/homologation/${this.homologationId}/service/${this.serviceId}/${this.user.role}/operational-technician/${menu.url}`
      if (this.user.profile_id == 1){
        path += `?provider_id=${this.$route.query.provider_id}`
      }
      this.selectedMenu = menu;
      this.$router.push(path);
      this.$refs.context.close();
    },
  },
  mounted() {
    this.basePath = `/homologation/${this.homologationId}/service/${this.serviceId}/${this.user.role}/operational-technician/`;
    this.homologationId = this.$route.params.homologation_id;
    this.serviceId = this.$route.params.service_id;
  },
  watch: {
    service_validation_history(menus) {
      if (menus.length > 0) {
        const currentMenu = (this.menus = menus.find(
          ({ base }) => base === "operational-technician"
        ));
        this.menus = currentMenu.children;
        this.selectedRoute();
      }
    },
  },
};
</script>
